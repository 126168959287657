import React from "react";

function Rooms({ roomsData, roomSetter }) {
    return (
        <section className="rooms sec-width" id="rooms">
            <div className="title">
                <h2>Nos chambres</h2>
            </div>
            <p>Toutes nos chambres sont assujéties à une taxe de séjour de 0.60€ par nuit par personne</p>

            <div className="rooms-container">
                {roomsData.map((room, index) => (
                    <article className="room" key={index}>
                        <div className="room-image">
                            <img src={room.image} alt="room image" />
                        </div>
                        <div className="room-text">
                            <h3>{room.title}</h3>
                            <ul>
                                {room.features.map((feature, index) => (
                                    <li key={index}>
                                        <i className="fas fa-arrow-alt-circle-right"></i>
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                            <p className="rate">
                                <span>{room.rate}€/</span> Par nuit
                            </p>
                            <a href="#book" className="btn" onClick={() => {roomSetter(room.title)}}>Réserver</a>
                        </div>
                    </article>
                ))}
            </div>
        </section>
    );
}

export default Rooms;
